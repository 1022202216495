import { artist, releases, recordings, works, products, socials, videos, events } from "@/data";
import { Collection } from "@/models/model";
import Artist from "@/models/artist";
import Release from "@/models/release";
import Recording from "@/models/recording";
import Work from "@/models/work";
import Product from "@/models/product";
import Social from "@/models/social";
import Video from "@/models/video";
import Event from "@/models/event";

class DB {
  readonly artist: Artist;
  readonly releases: Collection<Release>;
  readonly recordings: Collection<Recording>;
  readonly works: Collection<Work>;
  readonly products: Collection<Product>;
  readonly socials: Collection<Social>;
  readonly videos: Collection<Video>;
  readonly events: Collection<Event>;

  constructor() {
    this.artist = new Artist(artist);
    this.releases = new Collection<Release>(releases.map((release) => new Release(release)));
    this.recordings = new Collection<Recording>(recordings.map((recording) => new Recording(recording)));
    this.works = new Collection<Work>(works.map((work) => new Work(work)));
    this.products = new Collection<Product>(products.data.map((product) => new Product(product)));
    this.socials = new Collection<Social>(Object.values(socials).map((social) => new Social(social)));
    this.videos = new Collection<Video>(videos.map((video) => new Video(video)));
    this.events = new Collection<Event>(events.map((event) => new Event(event)));
  }
}

export const useDB = (): DB => new DB();
export const useArtist = (): Artist => useDB().artist;
export const useReleases = (): Collection<Release> => useDB().releases;
export const useRecordings = (): Collection<Recording> => useDB().recordings;
export const useWorks = (): Collection<Work> => useDB().works;
export const useProducts = (): Collection<Product> => useDB().products;
export const useSocials = (): Collection<Social> => useDB().socials;
export const useVideos = (): Collection<Video> => useDB().videos;
export const useEvents = (): Collection<Event> => useDB().events;
