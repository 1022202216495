enum LogLevel {
  INFO = "info",
  WARN = "warn",
  ERROR = "error",
  DEBUG = "debug",
}

class Logger {
  private static instance: Logger;

  static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }
    return Logger.instance;
  }

  private static computePrefix(): string {
    const trace = new Error().stack?.split("\n").slice(4);
    if (!trace) return "Logger";
    for (const line of trace) {
      const match = line.match(/\b(?:\/|\\)([a-zA-Z0-9]+)\.(vue|ts).*?:/);
      if (match) return match[1];
    }
    return "Logger";
  }

  info = (...args: unknown[]): boolean => this.log(LogLevel.INFO, ...args);

  warn = (...args: unknown[]): boolean => this.log(LogLevel.WARN, ...args);

  error = (...args: unknown[]): boolean => this.log(LogLevel.ERROR, ...args);

  debug = (...args: unknown[]): boolean => this.log(LogLevel.DEBUG, ...args);

  private log = (level: LogLevel, ...args: unknown[]): boolean => {
    const prefix = Logger.computePrefix();
    // Silences logs in production
    if (import.meta.env.PROD) {
      return true;
    }
    try {
      const color = {
        [LogLevel.INFO]: "lightblue",
        [LogLevel.WARN]: "orange",
        [LogLevel.ERROR]: "red",
        [LogLevel.DEBUG]: "gray",
      }[level];
      console.log(
        `%c[${level.toUpperCase()}]%c[${prefix}]`,
        `font-weight: bold; color: ${color}`,
        "font-weight: normal;",
        ...args,
      );
      return true;
    } catch (e) {
      console.error(`[${prefix}] Error while attempting to log message`, e);
      return false;
    }
  };
}

const logger = Logger.getInstance();

export default logger;
