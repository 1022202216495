import { Collection, Model, ModelWithAsset } from "@/models/model";
import type { RawImage, RawRelease, RawThumbnail } from "@/data";
import type Album from "@/models/album";
import { useDB } from "@/composables/useDB";
import type Recording from "@/models/recording";
import { useDate } from "@/composables/useDate";
import dayjs from "dayjs";

class ModelWithContentUrl extends Model {
  readonly contentUrl: string;

  constructor(model: RawImage | RawThumbnail, release: Release) {
    super(model);
    this.contentUrl = `https://coverartarchive.org/release/${release.id}/${model.id}.${model.encodingFormat}`;
  }
}

export class Thumbnail extends ModelWithContentUrl {
  readonly id: string;
  readonly type: string;
  readonly encodingFormat: string;
  readonly releaseId: string;

  constructor(thumbnail: RawThumbnail, release: Release) {
    super(thumbnail, release);
    this.id = thumbnail.id;
    this.type = thumbnail.type;
    this.encodingFormat = thumbnail.encodingFormat;
    this.releaseId = release.id;
  }

  get width(): number {
    return parseInt(this.id.split("-")[1]);
  }
}

export class Image extends ModelWithContentUrl {
  readonly id: string;
  readonly type: string;
  readonly encodingFormat: string;
  readonly representativeOfPage: string;
  readonly thumbnail: Collection<Thumbnail>;
  readonly releaseId: string;

  constructor(image: RawImage, release: Release) {
    super(image, release);
    this.id = image.id;
    this.type = image.type;
    this.encodingFormat = image.encodingFormat;
    this.representativeOfPage = image.representativeOfPage;
    this.thumbnail = new Collection<Thumbnail>(image.thumbnail.map((thumbnail) => new Thumbnail(thumbnail, release)));
    this.releaseId = release.id;
  }
}

class Release extends ModelWithAsset {
  readonly id: string;
  readonly name: string;
  readonly type: string;
  readonly catalogNumber?: string;
  readonly creditedTo: string;
  readonly duration: string;
  readonly gtin: string;
  readonly image: Image;
  readonly inLanguage: string;
  readonly musicReleaseFormat: string;
  readonly recordLabel: {
    id: string;
    name: string;
    type: string;
  };
  readonly releaseOf: {
    id: string;
  };
  readonly track: {
    id: string;
  }[];
  private readonly hasReleaseRegion: {
    type: string;
    releaseCountry: {
      id: string;
      name: string;
    };
    releaseDate: string;
  }[];

  constructor(release: RawRelease) {
    super(release);
    this.id = release.id;
    this.name = release.name;
    this.type = release.type;
    this.catalogNumber = release.catalogNumber;
    this.creditedTo = release.creditedTo;
    this.duration = release.duration;
    this.gtin = release.gtin;
    this.hasReleaseRegion = release.hasReleaseRegion;
    this.image = new Image(release.image, this);
    this.inLanguage = release.inLanguage;
    this.musicReleaseFormat = release.musicReleaseFormat;
    this.recordLabel = release.recordLabel;
    this.releaseOf = release.releaseOf;
    this.track = release.track;
  }

  get releaseDate(): dayjs.Dayjs {
    return useDate(this.hasReleaseRegion[0].releaseDate);
  }

  get UPC(): string {
    return this.gtin;
  }

  get label(): string {
    return this.recordLabel.name;
  }

  get catalog(): string | undefined {
    return this.catalogNumber;
  }

  get tracks(): Collection<Recording> {
    return useDB().recordings.filter((recording) => this.track.map((track) => track.id).includes(recording.id));
  }

  get album(): Album {
    return useDB().artist.albums.findBy("id", this.releaseOf.id!);
  }

  get backgroundColour(): { hex: string; twClass: string } {
    switch (this.name) {
      case "Plastic Ocean":
        return { hex: "#92281a", twClass: "!bg-plastic-ocean-800" };
      case "After the Storm (That Never Came)":
        return { hex: "#44403c", twClass: "!bg-stone-700" };
      case "Backworld":
        return { hex: "#022c22", twClass: "!bg-emerald-950" };
      case "Reality Behind The Illusion of Life":
        return { hex: "#451a03", twClass: "!bg-amber-950" };
      default:
        return { hex: "#44403c", twClass: "!bg-stone-700" };
    }
  }

  get assetName(): string {
    return `${this.album.releaseType}_${this.longSlug}`;
  }
}

export default Release;
