import { Model } from "@/models/model";
import dayjs from "dayjs";
import * as Contentful from "contentful";
import type {
  TypeBiography,
  TypeContact,
  TypeExternalLink,
  TypeFooter,
  TypeNews,
  TypeSocialLink,
} from "@/plugins/contentful/types";
import logger from "@/plugins/logger";
import { documentToHtmlString, type Options } from "@contentful/rich-text-html-renderer";
import { BLOCKS } from "@contentful/rich-text-types";

const renderOptions: Partial<Options> = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, next): string => `<h1 class="text-5xl font-thin p-2">${next(node.content)}</h1>`,
    [BLOCKS.HEADING_2]: (node, next): string => `<h2 class="text-4xl font-thin p-2">${next(node.content)}</h2>`,
    [BLOCKS.HEADING_3]: (node, next): string => `<h3 class="text-3xl font-thin p-2">${next(node.content)}</h3>`,
    [BLOCKS.HEADING_4]: (node, next): string => `<h4 class="text-2xl font-thin py-10">${next(node.content)}</h4>`,
    [BLOCKS.HEADING_5]: (node, next): string => `<h5 class="text-xl font-thin py-8">${next(node.content)}</h5>`,
    [BLOCKS.HEADING_6]: (node, next): string => `<h6 class="text-lg py-2">${next(node.content)}</h6>`,
    [BLOCKS.PARAGRAPH]: (node, next): string => `<p class="pb-3">${next(node.content)}</p>`,
    [BLOCKS.UL_LIST]: (node, next): string => `<ul class="list-inside">${next(node.content)}</ul>`,
    [BLOCKS.OL_LIST]: (node, next): string => `<ol class="list-inside">${next(node.content)}</ol>`,
    [BLOCKS.LIST_ITEM]: (node) => {
      // @ts-expect-error - It expects a Document but fuck that. Node works the same.
      const transformedChildren = documentToHtmlString(node, {
        renderNode: {
          [BLOCKS.PARAGRAPH]: (node, next) => `${next(node.content)}`,
          [BLOCKS.LIST_ITEM]: (node, next) => `${next(node.content)}`,
        },
      });
      return `<li>${transformedChildren}</li>`;
    },
  },
};

class News extends Model {
  title: Contentful.EntryFields.Symbol;
  slug: Contentful.EntryFields.Symbol;
  headline: Contentful.EntryFields.Symbol;
  date: dayjs.Dayjs;
  location: Contentful.EntryFields.Symbol;
  body: Contentful.EntryFields.RichText;
  featuredImage: Contentful.Asset;
  links: TypeExternalLink[];

  constructor(newsEntry: Contentful.Entry<TypeNews>) {
    logger.debug("News.constructor", newsEntry);
    super({
      id: newsEntry.sys.id,
      name: newsEntry.fields.title as unknown as string,
    });
    this.title = newsEntry.fields.title as unknown as Contentful.EntryFields.Symbol;
    this.slug = newsEntry.fields.slug as unknown as Contentful.EntryFields.Symbol;
    this.headline = newsEntry.fields.headline as unknown as Contentful.EntryFields.Symbol;
    this.date = dayjs(newsEntry.fields.date as unknown as string);
    this.location = newsEntry.fields.location as unknown as Contentful.EntryFields.Symbol;
    this.body = newsEntry.fields.body as unknown as Contentful.EntryFields.RichText;
    this.featuredImage = newsEntry.fields.featuredImage as unknown as Contentful.Asset;
    this.links = newsEntry.fields.links as unknown as TypeExternalLink[];
  }

  get formattedDate(): string {
    return this.date.format("MMMM D, YYYY");
  }

  get routeParams(): Record<string, string> {
    return {
      year: this.date.format("YYYY"),
      month: this.date.format("MM"),
      day: this.date.format("DD"),
      slug: this.slug,
    };
  }

  get featuredImageUrl(): string | undefined {
    return (this.featuredImage.fields.file?.url as string) ?? undefined;
  }

  get featuredImageAlt(): string | undefined {
    return (this.featuredImage.fields.description as string) ?? undefined;
  }

  renderBody(): string {
    return documentToHtmlString(this.body, renderOptions);
  }
}

export default News;

export class Footer extends Model {
  contacts: TypeContact[];
  socialLinks: TypeSocialLink[];
  constructor(footerEntry: Contentful.Entry<TypeFooter>) {
    super({
      id: footerEntry.sys.id,
      name: "Footer",
    });
    this.contacts = footerEntry.fields.contacts as unknown as TypeContact[];
    this.socialLinks = footerEntry.fields.socialLinks as unknown as TypeSocialLink[];
  }
}

export class Biography extends Model {
  title: Contentful.EntryFields.Symbol;
  description: Contentful.EntryFields.RichText;
  imageGallery: Contentful.Asset<Contentful.ChainModifiers, string>[];
  constructor(bioEntry: Contentful.Entry<TypeBiography>) {
    super({
      id: bioEntry.sys.id,
      name: "Biography",
    });
    this.title = bioEntry.fields.title as unknown as Contentful.EntryFields.Symbol;
    this.description = bioEntry.fields.description as unknown as Contentful.EntryFields.RichText;
    this.imageGallery = bioEntry.fields.imageGallery as unknown as Contentful.Asset[];
  }

  renderDescription(): string {
    return documentToHtmlString(this.description, renderOptions);
  }
}
