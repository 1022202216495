<script lang="ts" setup>
import Video from "@/models/video";
import LiteYouTubeEmbed from "vue-lite-youtube-embed";
import "vue-lite-youtube-embed/style.css";

const props = defineProps<{
  video?: Video;
  url?: string;
  title?: string;
}>();

let videoId: string | undefined | null = null;
// const videoId = props.video?.getYoutubeId() ?? (props.url ? new URL(props.url).searchParams.get("v") : props.url?.split("/").pop());
if (props.video) {
  videoId = props.video.getYoutubeId();
}
if (props.url) {
  try {
    const url = new URL(props.url);
    videoId = url.searchParams.get("v");
    if (!videoId) {
      throw new Error("No video ID provided");
    }
  } catch {
    videoId = props.url.split("/").pop();
  }
}
if (!videoId) {
  throw new Error("No video ID provided");
}
const title = props.video?.name ?? props.title ?? "Video";
</script>

<template>
  <LiteYouTubeEmbed
    iframeClass="w-full aspect-video shadow-xl shadow-black"
    :id="videoId"
    poster="maxresdefault"
    :cookie="false"
    :aspect-height="9"
    :aspect-width="16"
    :title="title"
  />
</template>
