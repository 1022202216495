<script lang="ts" setup>
import YoutubeVideo from "@/components/YoutubeVideo.vue";
import News, { Footer, Biography } from "@/models/news";
import { useSafeEmail } from "@/composables/useSafeEmail";
import { useNews } from "@/composables/useNews";

const props = defineProps<{
  news: News;
  locale: "en" | "it";
  full?: boolean;
}>();

let footer: Footer | undefined = undefined;
let bio: Biography | undefined = undefined;
if (props.full) {
  footer = await useNews().footer(props.locale);
  bio = await useNews().shortBio(props.locale);
}
</script>

<template>
  <article>
    <header class="uppercase mx-auto max-w-lg">
      <time datetime="{{ news.date.format('YYYY-MM-DD') }}">
        <RouterLink :to="{ name: 'newsPost', params: news.routeParams }">
          <span class="text-gray-500 text-sm font-semibold">{{ news.date.format("MMMM DD, YYYY") }}</span>
        </RouterLink>
      </time>
      <h1 class="text-5xl font-thin p-2">
        <RouterLink :to="{ name: 'newsPost', params: news.routeParams }">
          <span class="text-white">{{ news.headline }}</span>
        </RouterLink>
      </h1>
    </header>
    <section class="font-source-code-pro mx-auto max-w-lg">
      <div class="text-justify" v-html="news.renderBody()"></div>
    </section>
    <div v-if="full">
      <section v-for="(link, key) in news.links" :key="key">
        <div v-if="link.fields.type === 'Youtube'" class="mx-auto max-w-3xl">
          <YoutubeVideo :url="link.fields.url" :title="link.fields.title" />
        </div>
        <p class="font-source-code-pro mx-auto max-w-lg text-left">
          {{ link.fields.title }}:
          <a :href="link.fields.url as unknown as string" target="_blank">{{ link.fields.url }}</a>
        </p>
      </section>
      <figure v-if="news.featuredImageUrl" class="mx-auto max-w-3xl">
        <img :src="news.featuredImageUrl" :alt="news.featuredImageAlt" class="w-full" />
        <figcaption class="text-center text-sm text-gray-500 py-2">
          {{ news.featuredImage.fields.description ?? news.featuredImage.fields.title ?? news.featuredImageAlt }}
        </figcaption>
      </figure>
      <hr class="max-w-3xl" />
      <section v-if="bio" class="font-source-code-pro mx-auto max-w-lg">
        <div class="text-justify" v-html="bio?.renderDescription()"></div>
      </section>
      <footer v-if="footer" class="font-source-code-pro mx-auto max-w-md text-left">
        <h4 class="text-2xl font-thin">Contacts</h4>
        <ul class="list-inside">
          <li v-for="(contact, key) in footer.contacts" :key="key">
            <span>{{ contact.fields.key }}: {{ useSafeEmail(contact.fields.value as unknown as string) }}</span>
          </li>
        </ul>
        <h4 class="text-2xl font-thin py-10">Follow Disease Illusion</h4>
        <ul class="list-inside">
          <li v-for="(link, key) in footer.socialLinks" :key="key">
            <span
              >{{ link.fields.platform }}:
              <a :href="link.fields.url as unknown as string" target="_blank">{{
                link.fields.display ?? link.fields.url
              }}</a></span
            >
          </li>
        </ul>
      </footer>
    </div>
  </article>
</template>

<style lang="css" scoped>
@import "tailwindcss";

section {
  @apply py-10;
  @apply bg-black;
}

hr {
  @apply border-1;
  @apply border-gray-800;
  @apply w-2/3;
  @apply mx-auto;
  @apply mt-20;
}
</style>
