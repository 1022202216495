<script lang="ts" setup>
import NavbarComponent from "@/components/NavbarComponent.vue";
import SectionComponent from "@/components/SectionComponent.vue";
import HeadComponent from "@/components/HeadComponent.vue";
import { onMounted, onUnmounted, ref } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const product = route.params.product as string;

const props = defineProps<{
  url: string;
  time: number;
}>();

const url = product ? `${props.url}/product/${product}` : props.url;

const millisecondsLeft = ref(props.time || 1000);

let interval: number | undefined;

const startCountdown = (): void => {
  interval = setInterval(() => {
    millisecondsLeft.value -= 100;
    if (millisecondsLeft.value <= 0) {
      clearInterval(interval);
      window.location.href = url;
    }
  }, 100);
};

onMounted(() => {
  startCountdown();
});

onUnmounted(() => {
  clearInterval(interval);
});
</script>

<template>
  <NavbarComponent />
  <HeadComponent compact />
  <SectionComponent id="redirect">
    <p>Redirecting {{ millisecondsLeft > 1 ? "in " + millisecondsLeft : "now" }}</p>
  </SectionComponent>
</template>
