<script lang="ts" setup>
import SectionComponent from "@/components/SectionComponent.vue";
import { useEvents } from "@/composables/useDB";
import EventComponent from "@/components/EventComponent.vue";
import Event from "@/models/event";

const sortByDateAscending = (a: Event, b: Event): number => (a.startDate > b.startDate ? -1 : 1);
const events = useEvents().sort(sortByDateAscending).take(5);
</script>

<template>
  <SectionComponent
    id="shows"
    :parallax="true"
    background="rotellone_black_background"
    background-class="bg-cover bg-fixed bg-center bg-no-repeat"
    title="Shows"
  >
    <EventComponent v-for="event in events.items" :key="event.id" :event="event" />
    <a class="block p-5 font-plastic-ocean" href="/shows">View all shows</a>
  </SectionComponent>
</template>

<style lang="css" scoped></style>
