<script lang="ts" setup>
import SectionComponent from "@/components/SectionComponent.vue";
import { useMediaQuery } from "@vueuse/core";
import useScript from "@/composables/useScript";

const desktopWidget = "https://snapwidget.com/embed/972493";
const mobileWidget = "https://snapwidget.com/embed/1065573";
const isMobile = useMediaQuery("(max-width: 768px)");
const widgetId = isMobile.value ? mobileWidget : desktopWidget;

useScript("https://snapwidget.com/js/snapwidget.js");
</script>

<template>
  <SectionComponent
    id="social"
    background="polluted_ocean_2"
    :opacity="0.3"
    background-class="bg-repeat bg-[length:50%] bg-gray-400"
    title="@DISEASEILLUSION.MUSIC"
  >
    <div class="embed-responsive">
      <div id="snapwidget"></div>
      <iframe
        title="Disease Illusion on Instagram"
        :src="widgetId"
        allowtransparency="true"
        class="snapwidget-widget border-0 overflow-hidden w-full"
        frameborder="0"
        scrolling="no"
        style="border: none; overflow: hidden; width: 100%"
      >
      </iframe>
    </div>
  </SectionComponent>
</template>

<style></style>
