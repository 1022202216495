<script lang="ts" setup>
import RotelloneIcon from "@/components/RotelloneIcon.vue";
import { usePreferredReducedMotion } from "@vueuse/core";

const preferredReducedMotion = usePreferredReducedMotion();
const noAnimation = preferredReducedMotion.value === "reduce";
</script>

<template>
  <div class="fixed inset-0 bg-stone-900 flex justify-center items-center w-screen h-screen z-50 overflow-hidden">
    <div>
      <RotelloneIcon :class="noAnimation ? 'animate-none' : 'animate-pulse'" class="m-2 text-main size-32 block" />
      <div v-if="noAnimation" class="text-white text-center">Loading ...</div>
    </div>
  </div>
</template>

<style scoped></style>
