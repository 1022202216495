<script lang="ts" setup>
import { useRoute } from "vue-router";
import { useEvents } from "@/composables/useDB";
import FootComponent from "@/components/FootComponent.vue";
import NavbarComponent from "@/components/NavbarComponent.vue";
import SectionComponent from "@/components/SectionComponent.vue";
import HeadComponent from "@/components/HeadComponent.vue";

const route = useRoute();
const slug = route.params.slug as string;

const event = useEvents().findBy("slug", slug);
</script>

<template>
  <NavbarComponent />
  <HeadComponent compact />
  <SectionComponent id="event" blend="multiply" class="min-h-screen bg-top bg-repeat">
    <div class="w-full flex flex-row justify-center text-left bg-stone-900 my-4 p-5 mx-auto rounded-lg text-white">
      <div class="basis-3/4 items-left">
        <div class="pb-2">
          <div v-if="!event.isPast()" class="uppercase text-sm font-semibold">
            <span class="">{{ event.getStartDate() }}</span>
            <span class="p-2 text-gray-500">•</span>
            <span v-if="!event.canceled" class="text-xs text-gray-400">{{ event.getStartTime() }}</span>
            <span v-if="event.canceled" class="text-xs text-red-500">Canceled</span>
          </div>
          <div v-if="event.isPast()" class="uppercase text-sm">
            <span v-if="!event.canceled" class="text-xs text-gray-400">Past event</span>
            <span v-if="event.canceled" class="text-xs text-red-500">Canceled event</span>
            <span class="p-2 text-gray-500">•</span>
            <span>{{ event.getStartDate() }}</span>
          </div>
        </div>
        <div class="pb-2">
          <div class="text-xl font-semibold">{{ event.getCity() }}, {{ event.getCountry() }}</div>
          <div class="font-normal">{{ event.isFestival() ? event.getName() : event.getVenue() }}</div>
          <div class="text-sm font-normal">{{ event.getAddress() }}</div>
          <div class="text-sm font-normal" v-if="event.getMapLink()">
            <a :href="event.getMapLink()" target="_blank" rel="noreferrer">Get directions</a>
          </div>
        </div>
      </div>
      <div class="basis-1/4 content-center text-center">
        <img :src="event.getImage('small')" alt="event image" class="rounded-lg" />
      </div>
    </div>
  </SectionComponent>
  <FootComponent />
</template>

<style scoped></style>
