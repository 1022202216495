import { onMounted, onUnmounted } from "vue";

type scriptAttributes = {
  id?: string;
  async?: boolean;
  type?: string;
  onload?: () => void;
};

const defaultAttributes: scriptAttributes = {
  id: Math.random().toString(36).substring(7),
  async: false,
  type: "text/javascript",
};

const useScript = (src: string, attributes?: scriptAttributes): void => {
  const attr = { ...defaultAttributes, ...attributes };
  const script = document.createElement("script");
  script.src = src;
  script.id = attr.id!;
  script.async = attr.async!;
  script.type = attr.type!;
  if (attr.onload) script.onload = attr.onload;

  onMounted(() => document.head.appendChild(script));
  onUnmounted(() => document.head.removeChild(script));
};

export default useScript;
