import Member from "@/models/member";
import { Collection, Model } from "@/models/model";
import type { RawArtist } from "@/data";
import Album from "@/models/album";

class Artist extends Model {
  readonly id: string;
  readonly name: string;
  readonly type: string;
  readonly albums: Collection<Album>;
  readonly foundingDate: string;
  readonly genre: string[];
  readonly groupOrigin: {
    id: string;
    name: string;
    type: string;
    containedIn: {
      id: string;
      name: string;
      type: string;
      containedIn: {
        id: string;
        name: string;
        type: string;
        containedIn: {
          id: string;
          name: string;
          type: string;
        };
      };
    };
  };
  readonly location: {
    id: string;
    name: string;
    type: string;
    containedIn: {
      id: string;
      name: string;
      type: string;
      containedIn: {
        id: string;
        name: string;
        type: string;
        containedIn: {
          id: string;
          name: string;
          type: string;
        };
      };
    };
  };
  readonly members: Collection<Member>;
  readonly sameAs: string[];

  constructor(artist: RawArtist) {
    super(artist);
    this.id = artist.id;
    this.name = artist.name;
    this.type = artist.type;
    this.albums = new Collection(artist.album.map((album) => new Album(album)));
    this.foundingDate = artist.foundingDate;
    this.genre = artist.genre;
    this.groupOrigin = artist.groupOrigin;
    this.location = artist.location;
    this.members = new Collection<Member>(artist.member.map((member) => new Member(member)));
    this.sameAs = artist.sameAs;
  }

  currentMembers(): Collection<Member> {
    return this.members.filter((member) => member.isActive());
  }
}

export default Artist;
