import News, { Biography, Footer } from "@/models/news";
import router from "@/router";
import { usePreferredLanguages } from "@vueuse/core";
import contentful from "@/plugins/contentful";
import type { TypeBiography, TypeFooter, TypeNews } from "@/plugins/contentful/types";

type Locale = "en" | "it";

const languages = usePreferredLanguages();
const preferredLocale = languages.value[0].split("-")[0] as Locale;

const handleNotFound = (): never => {
  router.push({ name: "NotFound" });
  throw new Error("News not found");
};

const getAllNews = async (limit: number = 10, locale: Locale = preferredLocale): Promise<News[]> =>
  await contentful
    .getEntries<TypeNews>({
      content_type: "newsPost",
      include: 3,
      // @ts-expect-error - Contentful types are fucked up
      order: ["-fields.date"],
      locale,
      limit,
    })
    .then((entries) => entries.items.map((entry) => new News(entry)))
    .catch(handleNotFound);

const getNewsById = async (id: string, locale: Locale = preferredLocale): Promise<News> =>
  contentful
    .getEntry<TypeNews>(id, { locale })
    .then((entry) => new News(entry))
    .catch(handleNotFound);

const getNewsBySlug = async (slug: string, locale: Locale = preferredLocale): Promise<News> =>
  await contentful
    .getEntries<TypeNews>({
      content_type: "newsPost",
      include: 3,
      // @ts-expect-error - Contentful types are fucked up
      "fields.slug": slug,
      locale,
    })
    .then((entries) => new News(entries.items[0]))
    .catch(handleNotFound);

const getNewsByDatedSlug = async (
  year: string,
  month: string,
  day: string,
  slug: string,
  locale: Locale = preferredLocale,
): Promise<News> =>
  await contentful
    .getEntries<TypeNews>({
      content_type: "newsPost",
      include: 3,
      // @ts-expect-error - Contentful types are fucked up
      "fields.slug": slug,
      "fields.date": `${year}-${month}-${day}`,
      locale,
    })
    .then((entries) => new News(entries.items[0]))
    .catch(handleNotFound);

const getFooter = async (locale: Locale = preferredLocale): Promise<Footer> =>
  await contentful
    .getEntry<TypeFooter>("20XLclqKGaXecbCqHdCwEv", { locale })
    .then((entry) => new Footer(entry))
    .catch(handleNotFound);

const getBio = async (id: string, locale: Locale = preferredLocale): Promise<Biography> =>
  await contentful
    .getEntry<TypeBiography>(id, { locale })
    .then((entry) => new Biography(entry))
    .catch(handleNotFound);
const getShortBio = async (locale: Locale = preferredLocale): Promise<Biography> =>
  await getBio("2pwp5qEzG4pM4vMUknkfzz", locale);
const getFullBio = async (locale: Locale = preferredLocale): Promise<Biography> =>
  await getBio("33YNYH77DX6sUMyOqGl5ek", locale);

export const useNews = (): {
  all: (limit?: number, locale?: Locale) => Promise<News[]>;
  byId: (slug: string, locale?: Locale) => Promise<News>;
  bySlug: (slug: string, locale?: Locale) => Promise<News>;
  byDatedSlug: (year: string, month: string, day: string, slug: string, locale?: Locale) => Promise<News>;
  footer: (locale?: Locale) => Promise<Footer>;
  shortBio: (locale?: Locale) => Promise<Biography>;
  fullBio: (locale?: Locale) => Promise<Biography>;
} => ({
  all: getAllNews,
  byId: getNewsById,
  bySlug: getNewsBySlug,
  byDatedSlug: getNewsByDatedSlug,
  footer: getFooter,
  shortBio: getShortBio,
  fullBio: getFullBio,
});
