<script lang="ts" setup>
import NavbarComponent from "@/components/NavbarComponent.vue";
import FootComponent from "@/components/FootComponent.vue";
import SectionComponent from "@/components/SectionComponent.vue";
import ImageResponsive from "@/components/ImageResponsive.vue";
import HeadComponent from "@/components/HeadComponent.vue";
import { useReleases } from "@/composables/useDB";
import { AlbumReleaseType } from "@/models/album";

const releases = useReleases()
  // Filter out singles
  .filter((release) => release.album.releaseType !== AlbumReleaseType.SingleRelease)
  // Sort by release date
  .sort((a, b) => b.releaseDate.unix() - a.releaseDate.unix());
</script>

<template>
  <NavbarComponent />
  <HeadComponent compact />
  <SectionComponent id="music" class="min-h-screen bg-stone-700">
    <div class="flex flex-wrap justify-center gap-5">
      <div v-for="release in releases.items" :key="release.id" class="w-full sm:w-1/3">
        <RouterLink :to="{ name: 'release', params: { release: release.shortSlug } }">
          <ImageResponsive
            :alt="release.name"
            :remote="release.image"
            :local="release.assetName"
            class="aspect-square"
          />
        </RouterLink>
        <h2 class="text-xl font-bold">{{ release.name }}</h2>
      </div>
    </div>
  </SectionComponent>
  <FootComponent />
</template>
