import { Model } from "@/models/model";
import type { RawSocial } from "@/data";

class Social extends Model {
  name: string;
  url: string;
  musicService?: boolean;

  constructor(social: RawSocial) {
    super(social);
    this.name = social.name;
    this.url = social.url;
    this.musicService = social.musicService;
  }
}

export default Social;
