import productsJSON from "./products.json";
import socialsJSON from "./socials.json";
import videosJSON from "./videos.json";
import artistsJSON from "./artist.json";
import releasesJSON from "./releases.json";
import recordingsJSON from "./recordings.json";
import worksJSON from "./works.json";
import eventsJSON from "./events.json";

export type RawProduct = {
  url: string;
  sku: string;
  name: string;
  price: number;
  currency: string;
  image: string;
};
export const products = productsJSON as unknown as {
  meta: {
    updated_at: string;
    expire_date: string;
    is_cache: boolean;
  };
  data: RawProduct[];
};

export type RawSocial = {
  name: string;
  url: string;
  musicService?: boolean;
};
export const socials = socialsJSON as unknown as RawSocial[];

export type RawVideo = {
  heading: string;
  title: string;
  youtubeId: string;
  published: string;
  visible: boolean;
  premiere?: boolean;
};
export const videos = videosJSON as unknown as RawVideo[];

export type RawPlaceArea = {
  id: string;
  name: string;
  type: string;
  containedIn?: RawPlaceArea;
  context: string;
  sameAs: string[];
};

export type RawPlace = {
  id: string;
  name: string;
  type: string;
  address: string;
  area: RawPlaceArea;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  disambiguation: string;
  typeID: string;
};

export type RawEventRelation = {
  type: string;
  artist?: RawArtist;
  "attribute-ids": {
    [key: string]: string;
  };
  "attribute-values": {
    [key: string]: string;
  };
  attributes: string[];
  begin: string;
  direction: string;
  end: string;
  place?: RawPlace;
  ended: boolean;
  "source-credit": string;
  "target-credit": string;
  "target-type": string;
  "type-id": string;
  url?: {
    id: string;
    resource: string;
  };
};

export type RawEvent = {
  id: string;
  name: string;
  type: string;
  cancelled: boolean;
  disambiguation: string;
  images: {
    image: string;
    thumbnails: {
      250: string;
      500: string;
      1200: string;
      large: string;
      small: string;
    };
  }[];
  "life-span": {
    begin: string;
    end: string;
    ended: boolean;
  };
  relations: RawEventRelation[];
  setlist: string;
  time: string;
  "type-id": string;
};
export const events = eventsJSON as unknown as RawEvent[];

export type RawWork = {
  id: string;
  name: string;
  type: string;
  composer: {
    id: string;
    name: string;
    type: string[];
  }[];
  context: string;
  inLanguage: string;
  iswcCode: string;
  lyricist: {
    id: string;
    name: string;
    type: string[];
  };
  recordedAs: {
    id: string;
    name: string;
    type: string;
    duration: string;
  };
};
export const works = worksJSON as unknown as RawWork[];

export type RawRecording = {
  id: string;
  name: string;
  type: string;
  context: string;
  contributor: {
    type: string;
    contributor: {
      id: string;
      name: string;
      type: string[];
    };
    roleName: string;
  }[];
  duration: string;
  isrcCode: string;
  producer: {
    id: string;
    name: string;
    type: string[];
  };
  recordingOf: {
    id: string;
    name: string;
    type: string;
  };
};
export const recordings = recordingsJSON as unknown as RawRecording[];

export type RawThumbnail = {
  id: string;
  type: string;
  encodingFormat: string;
};

export type RawImage = {
  id: string;
  type: string;
  encodingFormat: string;
  representativeOfPage: string;
  thumbnail: RawThumbnail[];
};

export type RawRelease = {
  id: string;
  name: string;
  type: string;
  catalogNumber?: string;
  creditedTo: string;
  duration: string;
  gtin: string;
  hasReleaseRegion: {
    type: string;
    releaseCountry: {
      id: string;
      name: string;
    };
    releaseDate: string;
  }[];
  image: RawImage;
  inLanguage: string;
  musicReleaseFormat: string;
  recordLabel: {
    id: string;
    name: string;
    type: string;
  };
  releaseOf: RawAlbum;
  track: {
    id: string;
    trackNumber: string;
  }[];
};

export const releases = releasesJSON as unknown as RawRelease[];

export type RawAlbum = {
  id: string;
  name: string;
  type: string;
  albumProductionType: string;
  albumReleaseType: string;
  byArtist: {
    id: string;
    name: string;
    type: string;
    genre: string[];
  };
  creditedTo: string;
};

export type RawMember = {
  type: string;
  endDate: string;
  member: {
    id: string;
    name: string;
    type: string[];
  };
  roleName: string;
  startDate: string;
};

export type RawArtist = {
  id: string;
  name: string;
  type: string;
  album: RawAlbum[];
  foundingDate: string;
  genre: string[];
  groupOrigin: {
    id: string;
    name: string;
    type: string;
    containedIn: {
      id: string;
      name: string;
      type: string;
      containedIn: {
        id: string;
        name: string;
        type: string;
        containedIn: {
          id: string;
          name: string;
          type: string;
        };
      };
    };
  };
  location: {
    id: string;
    name: string;
    type: string;
    containedIn: {
      id: string;
      name: string;
      type: string;
      containedIn: {
        id: string;
        name: string;
        type: string;
        containedIn: {
          id: string;
          name: string;
          type: string;
        };
      };
    };
  };
  member: RawMember[];
  sameAs: string[];
};

export const artist = artistsJSON as unknown as RawArtist;
