<script lang="ts" setup>
import ImageResponsive from "@/components/ImageResponsive.vue";
import SectionComponent from "@/components/SectionComponent.vue";
import { MusicalNoteIcon, ShoppingCartIcon } from "@heroicons/vue/24/solid";
import { useHumanDate } from "@/composables/useDate";
import { useReleases } from "@/composables/useDB";

defineProps<{
  compact?: boolean;
}>();

const currentRelease = useReleases().findByName("Plastic Ocean");
const outNow = useHumanDate(currentRelease.releaseDate);

/** Return a random background image from the ones availabel */
const getBackground = (): string => {
  const backgrounds = ["header_displaced_1", "header_displaced_2", "header_no_ground"];
  return backgrounds[Math.floor(Math.random() * backgrounds.length)];
};
</script>

<template>
  <SectionComponent
    id="home"
    :class="compact ? 'h-auto' : 'h-full sm:h-screen min-h-full'"
    :parallax="true"
    :background="getBackground()"
    background-class="bg-cover bg-no-repeat bg-center"
    class="w-screen min-w-full bg-black content-center"
  >
    <div class="text-center">
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-0 sm:gap-10 m-5 sm:m-10">
        <div :class="compact ? 'sm:max-w-52' : 'sm:max-w-96'" class="w-full ml-auto">
          <ImageResponsive
            :alt="currentRelease.name + ' cover'"
            :local="currentRelease.assetName"
            :remote="currentRelease.image"
            class="shadow-xl shadow-amber-950 sm:w-full w-1/2 m-auto"
          />
        </div>
        <div :class="compact ? 'sm:max-w-52' : 'sm:max-w-96'" class="w-full mr-auto content-center">
          <div class="flex flex-wrap gap-0">
            <div class="w-full">
              <h2 :class="compact ? 'p-2' : 'p-5 text-3xl'">
                <span :class="compact ? 'p-0' : 'p-2'" class="block">New {{ currentRelease.album.releaseType }}</span>
                <span v-if="currentRelease.name" :class="compact ? 'p-0' : 'p-2'" class="block font-plastic-ocean">{{
                  currentRelease.name
                }}</span>
                <span :class="compact ? 'p-0' : 'p-2'" class="block">out {{ outNow }}</span>
              </h2>
            </div>
            <div class="w-1/2">
              <RouterLink to="shop">
                <p
                  :class="compact ? 'p-2' : 'p-5 text-2xl'"
                  class="bg-plastic-ocean-500 hover:bg-plastic-ocean-600 text-white font-plastic-ocean lowercase hover:text-white"
                >
                  <ShoppingCartIcon class="size-5 inline-block" />
                  Buy
                </p>
              </RouterLink>
            </div>
            <div class="w-1/2">
              <a :href="`/${currentRelease.shortSlug}`">
                <p
                  :class="compact ? 'p-2' : 'p-5 text-2xl'"
                  class="bg-plastic-ocean-500 hover:bg-plastic-ocean-600 text-white font-plastic-ocean lowercase hover:text-white"
                >
                  <MusicalNoteIcon class="size-5 inline-block" />
                  Listen
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SectionComponent>
</template>

<style lang="css" scoped></style>
